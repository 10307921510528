@use "./Breakpoints.module.scss";

.carousel-root {
  outline: none;
}

.carousel {
  position: relative;
  width: 100%;
}
.carousel * {
  box-sizing: border-box;
}
.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
}
.carousel .carousel {
  position: relative;
}
.carousel .control-arrow {
  outline: 0;
  border: 0;
  background: none;
  top: 50%;
  margin-top: -13px;
  font-size: 18px;
}
.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}
.carousel .thumbs {
  transition: all 0.15s ease-in;
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
}
.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
}
.carousel .thumb:focus {
  border: 3px solid #ccc;
  outline: none;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #333;
}
.carousel .thumb img {
  vertical-align: top;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
}
.carousel.carousel-slider .control-arrow {
  display: none;
}
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  transition: height 0.15s ease-in;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  display: flex;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
}
.carousel .slider-wrapper.axis-vertical {
  display: flex;
}
.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column;
}
.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
}
.carousel .slider.animated {
  transition: all 0.35s ease-in-out;
}
.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
}
.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}
.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0;
}
.carousel .slide .legend {
  position: absolute;
  right: 0;
  bottom: 0;
  color: var(--text);
  background-color: var(--layer-1_bg);
  padding: 2rem;
  box-sizing: border-box;
  height: 100%;
  width: 40%;
  max-height: 24rem;
  max-width: 28rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  transition: background-color 0.25s ease-in;
  border: 2px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: var(--layer-1_bg-hover);
  }
  &:focus {
    border: 2px solid var(--link);
  }
  .legend-content {
    margin-bottom: 1rem;
  }
  .h6 {
    display: block;
    font-size: 0.75rem;
    margin-bottom: 1rem;
    letter-spacing: 0.32px;
  }
  .icon-button {
    margin: -0.75rem;
  }
  p {
    width: 90%;
  }
}

.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 2rem;
  padding: 0;
  z-index: 1;
}
.carousel .control-dots .dot {
  transition: background-color 0.25s ease-in;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: var(--interactive-white_hover);
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: var(--brand);
}
.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
}
.carousel:hover .slide .legend {
  opacity: 1;
}
@media screen and (max-width: Breakpoints.$breakpoint-2) {
  .carousel .slide .legend {
    padding: 1rem;
  }
  .carousel .control-dots {
    margin: 1rem;
  }
}
@media screen and (max-width: Breakpoints.$breakpoint-4) {
  .carousel .control-dots {
    bottom: auto;
    top: 0;

    p {
      width: 100%;
    }
  }
  .carousel-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    .carousel-image {
      flex-shrink: 0;
    }
  }
  .carousel .slide .legend {
    max-height: none;
    position: relative;
    width: 100%;
    max-width: none;
  }
}
