@use "./Typography.module.scss";
@use "./Themes.module.scss";
@use "./Interactives.module.scss";
@use "./Breakpoints.module.scss";

:root {
  --interactive: #7d48ed;
  --interactive_hover: #5621c7;
  --interactive_active: #3a0c9c;
  --interactive_border: #7d48ed;
  --interactive-text: #ffffff;
  --interactive-white: #ffffff;
  --interactive-white-text: #161616;
  --interactive-white_hover: #f4f4f4;
  --interactive-white_active: #e0e0e0;
  --interactive-white_border: #ffffff;
  --accent-3: #7d48ed;
  --white-1: #ffffff;
  --text_size: 16px;
  --padding-lr: 2rem;
  --grid-gap: 4rem;
  --hero-padding-top: 12rem;
  --title-padding-bottom: 6rem;
  --padding-top: 3rem;
  --padding-bottom: 4rem;
  --size_base: 16px;
  --letter-spacing_base: 0;
  --size_label: 0.75rem;
  --size_hero: 6rem;
  --size_xxl: 3rem;
  --size_xl: 2.25rem;
  --size_l: 1.25rem;
  --padding-padded-left: 11rem;
  --list-margin: 1rem;
}

body,
html {
  height: 100%;
  width: 100%;
  font-size: var(--text_size);
  text-rendering: antialiased;
  background-color: var(--bg);
}

body.lock-scroll {
  overflow-y: hidden !important;
}

.main-page-wrapper {
  transition: 0.2s linear filter, 0.2s linear opacity;
  opacity: 1;
  &.wrapper-loading {
    filter: blur(8px);
    opacity: 0.05;
  }
}

.zi1 {
  position: relative;
  z-index: 1;
}

.name-title {
  display: inline-block;
  margin-bottom: 1rem;
  color: var(--text_label);
}

.col-video {
  width: 100%;
}

.video-background-container {
  position: relative;
  .quote-container {
    z-index: 2;
  }
  video {
    position: relative;
    z-index: 1;
    width: 100%;
  }
  margin-bottom: var(--padding-bottom);
}

.layer-1-bg {
  background-color: var(--layer-1_bg);
}

.master-container {
  font-family: "IBM Plex Sans", sans-serif;
  color: var(--text);
  background-color: var(--bg);
  @include Typography.base;
}

.content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 var(--padding-lr);
  &.no-intro-hero {
    margin-bottom: 4rem;
  }
  &.no-padding {
    padding: 0;
  }
  &.padded-left {
    padding-left: var(--padding-padded-left);
  }
  &.padded-right {
    padding-right: var(--padding-padded-left);
  }
}

.heading-paragraph-container {
  margin-top: 2rem;
}
.paragraph-link-container {
  margin-top: 1rem;
  .link-container {
    margin-bottom: 0.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.paragraphs-container {
  .paragraph {
    margin-bottom: 1rem;
    // &:last-of-type:not(:first-of-type) {
    //   margin-bottom: 0;
    // }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.standalone-title {
  margin-right: 1rem;
  padding: var(--padding-top) 0 var(--title-padding-bottom) 0;
}

._4-col {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: var(--grid-gap);
}

._3-col {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: var(--grid-gap);
}

._2-col-titled {
  display: grid;
  grid-template-columns: 14.5rem 1fr;
  grid-column-gap: var(--grid-gap);
  padding: var(--padding-top) 0 var(--padding-bottom) 0;

  h4 {
    margin-bottom: 1rem;
    color: var(--text_label);
  }

  & > .col:first-of-type {
    text-align: right;
  }

  & > .col {
    ._2-col {
      padding: var(--padding-top) 0 var(--padding-bottom) 0;
      &:first-of-type {
        padding: 0 0 var(--padding-bottom) 0;
      }
      &:last-of-type:not(:first-of-type) {
        padding: var(--padding-top) 0 0 0;
      }
    }
  }
}

._x-col-container {
  flex: 1;
  display: flex;
}

.description {
  max-width: 75%;
  margin-bottom: 2rem;
}

.grouped-buttons-container {
  .kss-button {
    margin-bottom: 1px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &.horizontal {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -2px;
    .kss-button {
      margin-right: 2px;
      margin-bottom: 2px;
      &:last-of-type {
        margin-right: 0;
        margin-bottom: 2px;
      }
    }
  }
}

._1-col {
  padding: 3rem 0 4rem 0;
  &.hero {
    padding: var(--hero-padding-top) 0 6rem 0;
    width: 75%;
  }
  &.article-hero {
    padding: var(--hero-padding-top) 0 3rem 0;
    width: 75%;
  }
}

._2-col {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: var(--padding-lr);
  padding: 3rem 0 4rem 0;
  &.hero {
    padding: var(--hero-padding-top) 0 6rem 0;
    .hero-subtitle-container {
      display: flex;
      justify-content: flex-end;
    }
    h3 {
      max-width: 36rem;
    }
    &.full-width {
      padding: 8rem 0 6rem 0;
      & > .col:first-of-type {
        padding-top: 4rem;
      }
    }
    .description-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h1 {
        margin-bottom: 3rem;
      }
    }

    .image-container {
      width: 100%;
      .image-loader {
        width: 100%;
      }
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.input-container {
  h4 {
    color: var(--text_label);
    @include Typography.label;
    margin-bottom: 0.75rem;
  }

  &.disabled h4 {
    color: var(--text_disabled);
  }
}

.select-container {
  position: relative;
  width: 100%;
  top: 0;
  border-radius: 2px;
  background-color: var(--layer-1_bg);
  border-bottom: 1px solid var(--accent-1);

  svg {
    cursor: pointer;
    height: 1.25rem;
    width: 1.25rem;
    position: absolute;
    right: 1.25rem;
    top: 1rem;
    fill: var(--text);

    path {
      fill: var(--text);
    }
  }

  &:hover {
    background-color: var(--layer-1_bg-hover);
  }
}

.select {
  position: relative;
  color: var(--text);
  transition: 0.2s ease-out background-color;
  border-radius: 0;
  margin: 0;
  cursor: pointer;
  font-size: inherit;
  padding: 1rem;
  width: calc(100%);
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;

  &::-ms-expand {
    display: none;
  }
}

.image-text-horizontal-container {
  display: flex;
  &.investor-logo {
    background-color: var(--bg);
    .image-container {
      mix-blend-mode: difference;
    }
  }
  .image-container {
    flex-shrink: 0;
    width: 10rem;
    height: 10rem;
    margin-right: 2rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.dotted-list {
  & > li {
    margin-bottom: var(--list-margin);
  }
}

.checkmark-container {
  svg path {
    &:first-of-type {
      fill: var(--white-1);
    }
    fill: var(--brand);
  }
}

.hero-wrapper {
  background-color: var(--layer-contrast_bg);
}

.hero-text-shadow {
  text-shadow: var(--layer-contrast_text-shadow);
}

.hero-container {
  display: flex;
  position: relative;
  flex-direction: column;
  background-size: cover;
  background-position: 50% 33%;
  overflow: hidden;
  color: var(--layer-contrast_text);
  min-height: 66vh;
  justify-content: center;
  @include Themes.light-contrast-selection;

  .hero-image {
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    .image-container img {
      object-fit: cover;
    }
  }
  &.half {
    height: 40vh;
    min-height: 400px;
    background-position: 50% 33%;
  }
  & > .content-container {
    position: relative;
    z-index: 2;
    flex: 1;
  }
}

.hero-subnav-wrapper {
  padding: 1.5rem 0;
  background-color: var(--layer-contrast_bg);
  color: var(--layer-contrast_text);
  @include Themes.light-contrast-selection;
}

.hero-subnav {
  display: flex;
  & > li {
    width: 12.5rem;
  }
}

.hero-subnav-number {
  margin-bottom: 1rem;
}

.link {
  @include Interactives.link;
}

.screenshot-image img,
.screenshot-video {
  border-radius: 4px;
  box-shadow: var(--screenshot-shadow);
}

.link-image {
  cursor: pointer;
}

.zoomable-media {
  cursor: zoom-in;
  position: relative;
  .zoom-indicator {
    position: absolute;
    z-index: 4;
    right: 0;
    top: 0;
  }
}

.zoomed-media-container {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--layer-1_bg);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.zoomed-media-content {
  width: calc(100% - (var(--padding-lr) * 2));
  height: calc(100% - (var(--padding-lr) * 2));
  position: relative;
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  img {
    object-fit: contain;
  }
}

.zoomed-media-controls-container {
  position: absolute;
  z-index: 1;
  right: var(--padding-lr);
  top: var(--padding-lr);
}

h1,
.h1 {
  @include Typography.hero;
  &.hero-slogan {
    @include Typography.homeHero;
  }
  &.display-serif {
    @include Typography.displaySerif;
  }
}

h2,
.h2 {
  @include Typography.xxl;
}

h3,
.h3 {
  @include Typography.xl;
  line-height: 120%;
}

.big-text {
  @include Typography.l;
  line-height: 133%;
}

.span {
  display: inline;
}

.p-link {
  margin-top: 1rem;
}

.normalize-text {
  font-size: inherit;
  letter-spacing: normal;
  font-weight: inherit;
}

p,
.p {
  line-height: 150%;
}

.brand-emphasize {
  color: var(--link);
}

b {
  font-weight: 600;
}

.tm {
  position: relative;

  &::after {
    content: "™";
    font-size: 33%;
    margin-left: 0.75%;
    display: inline;
    position: absolute;
    top: 28%;
    line-height: 33%;
  }
}

.column-fade-enter,
.column-fade-appear {
  position: relative;
  top: -0.75rem;
  opacity: 0;
  transition: opacity 1s ease-out, top 1s ease-out;
  .splitter {
    width: 1px;
  }
  @for $i from 1 through 3 {
    &:nth-child(#{$i}n) {
      transition-delay: #{$i * 0.2}s;
    }
  }
}

.column-fade-enter-active,
.column-fade-appear-active {
  position: relative;
  top: 0;
  opacity: 1;
  transition: opacity 1s ease-out, top 1s ease-out;
  .splitter {
    width: 100%;
  }
}

.column-fade-exit {
  position: relative;
  top: -1rem;
  opacity: 1;
  .splitter {
    width: 1px;
  }
}

.column-fade-exit-active {
  position: relative;
  top: -1rem;
  opacity: 0;
  transition: opacity 1s ease-out, top 1s ease-out;
  .splitter {
    width: 1px;
  }
}

.menu-fade-enter,
.menu-fade-appear {
  opacity: 0;
  transition: opacity 1s ease-out;
}
.menu-fade-enter-active,
.menu-fade-appear-active {
  opacity: 1;
  transition: opacity 0.1s ease-out;
}

.menu-fade-exit {
  opacity: 1;
}

.menu-fade-exit-active {
  opacity: 0;
  transition: opacity 0.1s ease-out;
}

.contact-form {
  label {
    display: block;
    margin-bottom: 1.5rem;
  }
}

.contact-success {
  margin-top: 2rem;
}

.image-fade-loader {
  &.image-loader .image-loader {
    border: 0px dashed transparent;
  }
  .image-container {
    opacity: 0;
    transition: 0.33s linear opacity;
  }
  &.dark-loader {
    background-color: var(--layer-contrast_bg);
  }
  &.image-enter-done {
    .image-container {
      opacity: 1;
    }
  }
}

.image-loader {
  position: relative;
  .image-container img {
    height: 100%;
    width: 100%;
  }
  .image-img {
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 1;
    z-index: 1;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50% 33%;
  }

  .image-slug {
    height: 100%;
    width: 100%;
    background-color: var(--bg);
    transition: 0.25s height ease-out;
    position: absolute;
    opacity: 1;
    z-index: 2;
    bottom: 0;
    left: 0;
  }

  .image-loader {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    border: 2px dashed var(--brand);
  }

  &.image-enter-done {
    .image-loader {
      border: 0px dashed transparent;
    }
    .image-slug {
      height: 0;
    }

    .image-image {
      opacity: 1;
    }
  }
}

.pseudo-icon-button svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--link);
  path {
    fill: var(--link);
  }
}

.editions-table {
  width: 100%;
  border-collapse: collapse;
  line-height: 1.25;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  tr > :first-child {
    padding-left: 0;
    border-left: none;
    text-align: left;
    min-width: unset;
  }
  tr > :last-child {
    border-right: none;
  }
  td,
  th {
    padding: 1rem;
    vertical-align: middle;
    min-width: 4rem;
    text-align: center;
    border-left: 1px solid var(--accent-1);
    border-right: 1px solid var(--accent-1);
  }

  tr {
    border-bottom: 1px solid var(--accent-1);

    &:last-of-type:not(:first-of-type) {
      border-bottom: none;
    }
  }

  thead th {
    font-weight: 600;
  }

  margin-bottom: 0;
}

.limited-superscript {
  vertical-align: super;
  font-size: 0.75rem;
}

.limited-definition {
  margin-top: 2rem;
  background-color: var(--layer-1_bg);
  display: inline-block;
  padding: 0.625rem 1rem 1rem 1rem;
}

.asteroids-game-container {
  display: flex;
  justify-content: center;
  align-items: center;

  #asteroids-game-canvas {
    cursor: pointer;
    border: 3px solid var(--link);
    animation: game-border-pulsate 2.5s infinite;
  }

  &.active {
    #asteroids-game-canvas {
      cursor: default;
      animation: none;
      border: 3px solid var(--accent-1);
    }
  }
}

@keyframes game-border-pulsate {
  0% {
    border-color: rgba(125, 72, 237, 1);
  }

  50% {
    border-color: rgba(125, 72, 237, 0);
  }

  100% {
    border-color: rgba(125, 72, 237, 1);
  }
}

.error-window {
  position: fixed;
  z-index: 2;
  background: var(--brand);
  color: var(--white-1);
  padding: var(--padding-top) var(--padding-lr) var(--padding-bottom)
    var(--padding-lr);
  width: 100%;
  bottom: 0;
}

.empty.ornament {
  position: relative;

  &::after {
    position: absolute;
    display: block;
    height: 0.25rem;
    width: 2rem;
    right: 0;
    bottom: 0;
    content: " ";
    background-color: var(--text);
  }
}

main {
  min-height: 100vh;
}

@media screen and (max-width: Breakpoints.$breakpoint-1) {
  :root {
    --size_base: 14px;
    --letter-spacing_base: 0.16px;
  }
}

@media screen and (max-width: Breakpoints.$breakpoint-2) {
  :root {
    --hero-padding-top: 6rem;
  }
  .splitter.hero-margin {
    margin-top: var(--padding-top);
  }
  .sections-container > section:first-of-type {
    padding-top: 2rem;
  }
  .content-container.padded-left {
    padding-left: var(--padding-lr);
  }
  ._2-col.hero.full-width {
    padding-top: var(--hero-padding-top);
    & > .col:first-of-type {
      padding-top: 0rem;
    }
  }
  .hero-subnav-wrapper {
    display: none;
  }
  ._2-col-titled {
    grid-template-columns: 14rem 1fr;
  }
  .error-window {
    bottom: 55px;
  }
}

@media screen and (max-width: Breakpoints.$breakpoint-3) {
  :root {
    --padding-top: 3rem;
    --padding-bottom: 3rem;
    --hero-padding-top: 4rem;
    --size_hero: 4rem;
    --size_xxl: 2.5rem;
    --size_xl: 1.5rem;
    --size_l: 1rem;
  }
  .empty.ornament::after {
    display: none;
  }
  .hero-subnav-wrapper {
    display: none;
  }
  .col {
    h2,
    h3,
    p {
      width: 80%;
    }
  }
  ._2-col-titled,
  ._2-col,
  ._3-col,
  ._4-col {
    grid-template-columns: 1fr;
    & > .col {
      &.empty {
        padding: 0;
      }
      padding: 0 0 var(--padding-top) 0;
    }

    & > .col:last-of-type {
      padding: 0;
    }
  }
  .mobile-no-padding-bottom {
    padding-bottom: 0 !important;
  }
  .mobile-1rem-padding-bottom {
    padding-bottom: 1rem !important;
  }
  ._2-col.hero {
    padding: var(--hero-padding-top) 0 var(--padding-lr) 0;
    & > .col {
      padding-bottom: 3rem;
      &.image-container {
        justify-content: center;
        padding-bottom: 0;
      }
      &.hero-subtitle-container {
        padding-bottom: 0;
        justify-content: flex-start;
        .hero-subtitle {
          width: 100%;
        }
      }
    }
  }
  .image-text-horizontal-container {
    flex-direction: column;
    .image-container {
      margin: 0 0 var(--padding-lr) 0;
    }
  }
}

@media screen and (max-width: Breakpoints.$breakpoint-4) {
  .col {
    h2,
    h3,
    p {
      width: 100%;
    }
  }
  :root {
    --padding-lr: 1rem;
  }
}
